body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-sm{
  font-size: 1.0rem;
  font-weight: 300;
  line-height: 1.2;
}
.text-md{
  font-size: 2.0rem;
  font-weight: 300;
  line-height: 1.2;
}
.text-lg{
  font-size: 3.0rem;
  font-weight: 300;
  line-height: 1.2;
}

p {
  text-indent:60px;
  margin-bottom:0px;
  margin-top:0px;
  text-align:justify;
}

.lesson-header{
  position: relative;
  background-image: linear-gradient(#298dff, #3e41ee);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 4vw)
  );
  /* change the calc height to a percentage height to get alternate responsive behavior*/
}

.lesson-header::after polygon {
  fill: white;
}


.video-header{
  position: relative;
  background-image: linear-gradient(#298dff, #3e41ee);
  clip-path: polygon(
    0 6%,
    100% 0,
    100% 100%,
    0 94%
  );
}

.video-header::after polygon {
  fill: white;
}

.radio-green {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.radio-green {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.radio-green:checked ~ .custom-control-label::before {
  color: rgb(31, 162, 101) !important;
  border-color: rgb(31, 162, 101) !important;
  background-color: rgb(31, 162, 101) !important;
}

.radio-green:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.radio-green:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.radio-green:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.radio-green:disabled ~ .custom-control-label {
  color: #6c757d;
}

.radio-green:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.bg-colored{
  background-color: #00be20 !important;
}

.btn-rounded-top{
  border-top-left-radius:1em !important; 
  border-top-right-radius:1em !important;
  border-bottom-left-radius:0 !important;
  border-bottom-right-radius:0 !important;
}

.btn-rounded-bottom{
  border-top-left-radius:0 !important; 
  border-top-right-radius:0 !important;
  border-bottom-left-radius:1em !important;
  border-bottom-right-radius:1em !important;
}

.speech-bubble {
	position: relative;
	background: transparent;
	border-radius: .4em;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 8px solid transparent;
	border-bottom-color: #007bff;
	border-top: 0;
	margin-left: -8px;
  margin-top: -8px;
  text-shadow: 2px 2px 2px 2px;
}

.text-shadow{
  text-shadow: 2px 2px 2px #000000 !important;
}

.bg-trans{
  background-color: transparent;
}

.hover-scale{
  transition: all .2s ease-in-out;
}
.hover-scale:hover{
  transform: scale(1.1); 
}

.hover-scale-sm{
  transition: all .2s ease-in-out;
}
.hover-scale-sm:hover{
  transform: scale(1.03); 
}

.hover-shadow{
  transition: all .2s ease-in-out;
}
.hover-shadow:hover{
  box-shadow: 2px 5px 5px rgba(0,0,0,0.3);
}

.ease-in-out{
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.no-animation{
  transition: all 0s !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-1 {
  background: linear-gradient(180deg,rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
}

.gradient-2 {
  background: linear-gradient(320deg,rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

p { color: #333; font-family: 'Muli', sans-serif; margin-bottom: 15px; }

.btn-circle.btn-xl {
  width: 120px;
  height: 120px;
  padding: 10px 16px;
  font-size: 34px;
  line-height: 1.33;
  border-radius: 60px;
}

.element-that-is-to-be-under{
  -webkit-box-shadow: inset 0 8px 4px -4px #ddd;
  -moz-box-shadow: inset 0 8px 4px -4px #ddd;
  box-shadow: inset 0 8px 4px -4px #ddd;
}

.no-decoration{
  text-decoration: none;
}

.home-header-bg{
  animation-duration: 1.0s;
  animation-delay: 0.5s;
}

.home-header-title{
  animation-duration: 1.8s !important;
}

.shadow-top-inner{
  -webkit-box-shadow: inset 1px 4px 9px -6px;
  -moz-box-shadow: inset 1px 4px 9px -6px;
  box-shadow: inset 1px 4px 9px -6px;
}

.card{
  max-width: 200px;
}

.p-nav{
  padding-top: 100px;
}

.insulfilm{
  position: fixed !important;
  margin:0 !important;
  padding: 0 !important;
  top:0 !important;
  left: 0 !important;
  bottom: 0 !important;
  z-index: 2 !important;
  overflow-y: auto !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.font-lg{
  font-size: 24px;
}
.font-sm{
  font-size: 14px;
}
.font-smaller{
  font-size: 12px;
}

.main-content{
  max-width: 650px;
}

.hover-light{
  transition: all .2s ease-in-out;
  background-color: rgb(255, 250, 238) !important;
}

.hover-light-out{
  transition: all .2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.85);
}

.bg-fixed{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient( rgba(255,255,255,1) 30%,rgba(255,255,255,0)), url("https://images.pexels.com/photos/999267/pexels-photo-999267.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260");
}

.bg-fixed-2{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient( rgba(255,255,255,1) 10%,rgba(255,255,255,0)), url("https://images.pexels.com/photos/3932232/pexels-photo-3932232.jpeg?auto=compress&cs=tinysrgb&h=650&w=940");
}

.single-post-bg{
  background-color: transparent
}

.bg-colored-2{
  background-color: transparent
}

.font-bold{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 900;
  letter-spacing: -8px;
  line-height: 80px;
}

.border-left-dotted{
  background-image:radial-gradient(circle at 2px, rgb(199, 199, 199) 1px, rgba(255,255,255,0) 2px);
  background-position: left;
  background-size: 9px 9px;
  background-repeat: repeat-y;
}

.border-bottom-dotted{
  background-image:radial-gradient(circle at 2px, rgb(199, 199, 199) 1px, rgba(255,255,255,0) 2px);
  background-position: bottom;
  background-size: 7px 9px;
  background-repeat: repeat-x; 
  animation: bounceInDown 0.5s; 
}

.border-left-primary{
  border-left: 1px rgba(0, 0, 0, 0.164) solid;
}


@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}



.btn-primary-sm {
  color: #403e34;
  background-color: #ffe600;
  border-color: #ffe600;
}

.btn-primary-sm:hover {
  color: #ffe600;
  background-color: #403e34;
  border-color: #000000;
  text-decoration: none;
}

.btn-primary-sm:focus, .btn-primary-sm.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary-sm.disabled, .btn-primary-sm:disabled {
  color: #fff;
  background-color: #ffe600;
  border-color: #ffe600;
}

.btn-primary-sm:not(:disabled):not(.disabled):active, .btn-primary-sm:not(:disabled):not(.disabled).active,
.show > .btn-primary-sm.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary-sm:not(:disabled):not(.disabled):active:focus, .btn-primary-sm:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-sm.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}


.btn-secondary-sm {
  color: #ffe600;
  background-color: #403e34;
  border-color: #000000;
}

.btn-secondary-sm:hover {
  color: #403e34;
  background-color: #ffe600;
  border-color: #ffe600;
  text-decoration: none;
}

.btn-secondary-sm:focus, .btn-secondary-sm.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary-sm.disabled, .btn-secondary-sm:disabled {
  color: #fff;
  background-color: #ffe600;
  border-color: #ffe600;
}

.btn-secondary-sm:not(:disabled):not(.disabled):active, .btn-secondary-sm:not(:disabled):not(.disabled).active,
.show > .btn-secondary-sm.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-secondary-sm:not(:disabled):not(.disabled):active:focus, .btn-secondary-sm:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary-sm.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}



.panel {
  --xy: 30% 85%; 
  --b: 31deg;
  --a: 121deg; 
  --c0: #3f35321f;
  --c1: #ce924877;
  --c2: #e4c16086;
  --c3: #db9c4e27;
	border-radius: 2px;
  box-shadow: 0px 0px 0px;
  background-image: linear-gradient(50deg, rgba(245, 175, 23, 0.5), rgba(11, 34, 48, 0.5)), url('https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
}

.perfect-circle-1 {
  min-width:'250px';
  max-width:'250px';
  min-height:'250px';
  max-height:'250px';
  margin:0;
  padding:0;
  align-items:'center';
}