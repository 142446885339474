.task-title{
  font-size: 28px !important;
}

.already-done-btn-parent .done-btn-child{
  color: black !important;
  z-index: 1 ;
}

.already-done-btn-parent{
  background-color: rgb(255, 204, 35) !important;
  color: rgb(197, 160, 39) !important;
}


.done-btn-parent .done-btn-child{
  color: transparent !important;
}

.done-btn-parent:hover .done-btn-child{
  color: black !important;
  z-index: 1;
}

.done-btn-parent:hover{
  background-color: rgb(255, 204, 35) !important;
  color: rgb(197, 160, 39) !important;
}


.ripple {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.ripple::after {
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-position: 50%;
  background-repeat: no-repeat;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(10, 10);
  transition: transform .5s, opacity 1s;
  width: 100%;
}

.ripple:active::after {
  opacity: .3;
  transform: scale(0, 0);
  transition: 0s;
}